@import "../../sass/colors.scss";
@import "../../sass/fonts.scss";

.icon-box {
  background: $color-white;
  width: 80px;
  height: 80px;
  line-height: 80px;
  border-radius: 20px;
  position: relative;
  z-index: 0;
  text-align: center;

  &::before {
    position: absolute;
    content: "";
    width: 100px;
    height: 100px;
    background: $color-white;
    top: -10px;
    left: -10px;
    z-index: -1;
    opacity: 0.3;
    border-radius: 20px;
  }

  img {
    width: 100%;
  }
}

// Mobile Responsive
@media (max-width: 575px) {
  .icon-box {
    width: 40px;
    height: 40px;
    line-height: 40px;
    border-radius: 8px;

    &::before {
      width: 60px;
      height: 60px;
      border-radius: 10px;
    }

    img {
      // max-width: 25px;
    }
  }
}

@media (min-width: 576px) and (max-width: 767px) {
  .icon-box {
    width: 40px;
    height: 40px;
    line-height: 40px;
    border-radius: 8px;

    &::before {
      width: 60px;
      height: 60px;
      border-radius: 10px;
    }

    // img {
    //   max-width: 25px;
    // }
  }
}
