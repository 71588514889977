@keyframes trin {
  from {
    transform: rotate3d(0, 0, 1, 0deg);
  }
  20%,
  32%,
  44%,
  56%,
  68% {
    transform: rotate3d(0, 0, 1, 0deg);
  }
  23%,
  35%,
  47%,
  59%,
  71% {
    transform: rotate3d(0, 0, 1, 15deg);
  }
  26%,
  38%,
  50%,
  62%,
  74% {
    transform: rotate3d(0, 0, 1, 0deg);
  }
  29%,
  41%,
  53%,
  65%,
  77% {
    transform: rotate3d(0, 0, 1, -15deg);
  }
  80% {
    transform: rotate3d(0, 0, 1, 0deg);
  }
}

@keyframes grow-shrink {
  0%,
  100% {
    transform: scale(1);
    opacity: 0.1;
  }
  50% {
    transform: scale(1.7);
    opacity: 1;
  }
}

@keyframes gelatine {
  from,
  to {
    transform: scale(1, 1);
  }
  25% {
    transform: scale(0.9, 1.1);
  }
  50% {
    transform: scale(1.1, 0.9);
  }
  75% {
    transform: scale(0.95, 1.05);
  }
}

@keyframes complex-motion {
  0%,
  100% {
    transform: scale(1);
  }
  50% {
    transform: scale(1.2) translate(10px, 5px);
  }

  25% {
    transform: scale(1.05) translate(-5px, -3px);
  }
  75% {
    transform: scale(1.15) translate(5px, -5px);
  }
}

@keyframes swing {
  0%,
  100% {
    transform: rotate(0deg);
  }
  50% {
    transform: rotate(-10deg);
  }

  25% {
    transform: rotate(-5deg);
  }
  75% {
    transform: rotate(5deg);
  }
}

@keyframes wave-motion {
  0%,
  100% {
    transform: rotate(0deg);
  }
  50% {
    transform: rotate(-5deg);
  }

  25% {
    transform: rotate(-2.5deg);
  }
  75% {
    transform: rotate(2.5deg);
  }

  12.5% {
    transform: rotate(-1.25deg);
  }
  87.5% {
    transform: rotate(1.25deg);
  }

  6.25% {
    transform: rotate(-0.625deg);
  }
  93.75% {
    transform: rotate(0.625deg);
  }
}

@keyframes phone {
  0% {
    transform: scale(0.7);
  }
  100% {
    transform: scale(1);
  }
}
@keyframes ShineAnim{
  0%{left: -100%}
  10%{left: 100%}
  100%{left: 100%}
}
